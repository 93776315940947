.center {
	text-align: center;
}
.padding-title {
	padding: 15px 0 30px 0;
}
.padding-stats {
	padding: 15px 0 30px 0;
	margin: 0 auto;
}
.width-70 {
	width: 70%;
	margin: 0 auto;
}
.slider-stats {
	width: 87%;
	text-align: right;
	margin: 3rem 0 1.5rem 0;
}
.small-font-size {
	font-size: 0.85rem;
}
.isa-notes-title {
	padding: 15px 0 15px 0;
}
