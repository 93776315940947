.Footer-module--footer {
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.29;
	letter-spacing: 0.16px;
	width: 100%;
	background: #000;
	color: #c6c6c6;
	position: relative;
}
.Footer-module--grid {
	min-height: 330px;
	padding-top: 2rem;
	padding-bottom: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: #f4f4f4;
}
.footer-text-color > .bx--list__item {
	color: #f4f4f4;
}
.footer-text {
	color: #c6c6c6;
}
.footer-link {
	margin: 0 0 0.5rem;
}

